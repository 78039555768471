.navigation {
  .logo {
    margin-right: .2em;
    margin-bottom: .2em;
    height: auto;
  }
  .navlogo {
    text-align: center;
    font-size: 1.1em;
    font-family: 'Roboto Condensed', sans-serif;
    color:darkslategray;
  }
  li {
    display: block;
    justify-content: space-between;
    padding: 20px 14px;
    border-bottom: 1px solid lightgrey;
    svg {
      display: inline-block;
    }
    a {
      display: block;
      text-decoration: none;
    }
  }
}

.logo {
  height: 50px;
  width: 50px;
}

.nav-links-container {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .nav-link {
    padding: 10px 15px;
    cursor: pointer;
  }
}

* {
  box-sizing: border-box;
}
